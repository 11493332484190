import {
  CourseDifficulty,
  EnrollmentStatus,
  PathGoalEnum,
} from '@mono/data/codegen/client/graphql-gateway/graphql';
import {
  careerPathHasJourney,
  getTimeToComplete,
} from '@mono/data/curriculum-cards';
import { getLearnPath, getPathImageUrl } from '@mono/util/url';

import { HomepageProps } from './Homepage';

export const defaultHeroTitle =
  'Join the millions learning to code with Codecademy for free';

export const defaultLearnerStoriesData: HomepageProps['contentfulData']['learnerStories'] =
  {
    heading: 'Stories from real people',
    description: 'Read inspiring stories from the Codecademy community.',
    stories: [
      {
        heading: 'Freelancer to Developing Apps for NASA',
        description: "De'Shaun B., Associate Software Engineer, Winter Park",
        callToActionUrl:
          'https://www.codecademy.com/resources/blog/from-freelance-web-designer-to-nasa-software-engineer/',
        imageUrl:
          'https://images.ctfassets.net/go6kr6r0ykrq/Sk3Knek82fDxSX5eZqdzX/bac59c63ff86d8d52ef9e1559e8eb381/De-Shaun-Broadnax.jpeg',
      },
      {
        heading: 'Lessons from a Product Owner turned Engineer',
        description: 'Serena I., Software Developer @ Adidas, South Holland',
        callToActionUrl:
          'https://www.codecademy.com/resources/blog/how-i-went-from-sales-to-front-end-engineer-at-adidas-in-15-months/',
        imageUrl:
          'https://images.ctfassets.net/go6kr6r0ykrq/2ao4ZIQinCIgAt4UuGNR3z/b5b0608e9ae47b42ba6aa5e18e97411e/SerenaIsone1.jpeg',
      },
      {
        heading: 'From Film to Full-Stack Engineer in 11 Months',
        description: 'Julia J., Ruby on Rails Developer, São Paulo',
        callToActionUrl:
          'https://www.codecademy.com/resources/blog/from-film-producer-to-full-stack-engineer-in-11-months/',
        imageUrl:
          'https://images.ctfassets.net/go6kr6r0ykrq/IEAxMFzasjkSr11XsNGso/ef9c1fcd18c5abf06e8064dc5352060c/Julia-Jubileu.jpeg',
      },
      {
        heading: 'Technical Analyst to Front-End Engineer in 6 Months',
        description:
          'Cristian T., Front-End Engineer @ Grid Dynamics, Guadalajara',
        callToActionUrl:
          'https://www.codecademy.com/resources/blog/from-technical-analyst-to-front-end-engineer/',
        imageUrl:
          'https://images.ctfassets.net/go6kr6r0ykrq/6zlCvqSouWnN7NGDTvidvT/3d4f8caef82cdaef8017edfd7e57f94f/Cristian-Tera__n.jpeg',
      },
    ],
  };

export const defaultGoalsData: HomepageProps['contentfulData']['goals'] = [
  {
    title: 'AI',
    trackingTitle: 'ai',
    description: ' ',
    relatedContent: {
      courses: [
        {
          id: '28ccb695c353465a8785a036ad32d3b9',
          slug: 'intro-to-generative-ai',
          urlPath: getLearnPath({ slug: 'intro-to-generative-ai' }),
          title: 'Intro to Generative AI',
          lessonCount: 1,
          grantsCertificate: true,
          enrollmentStatus: EnrollmentStatus.None,
          pro: false,
          shortDescription:
            'Dive into the many forms of generative AI and learn how we can best use these new technologies!',
          difficulty: CourseDifficulty.Beginner,
          timeToComplete: getTimeToComplete(0.38),
          type: 'course',
        },
        {
          id: 'ef9a06323b7d44b091a3ec7816092dc6',
          slug: 'intro-to-chatgpt',
          urlPath: getLearnPath({ slug: 'intro-to-chatgpt' }),
          title: 'Learn How to Use ChatGPT',
          lessonCount: 6,
          grantsCertificate: true,
          enrollmentStatus: EnrollmentStatus.None,
          pro: false,
          shortDescription:
            'Ready to dive into the world of Generative AI? Learn how ChatGPT works, how to use ChatGPT in your everyday life, and how to write effective ChatGPT prompts.',
          difficulty: CourseDifficulty.Beginner,
          timeToComplete: getTimeToComplete(1.04),
          type: 'course',
        },
        {
          id: '402dfacd31314cd693b1a893fde9ca7b',
          slug: 'prompt-engineering-for-software-engineers',
          urlPath: getLearnPath({
            slug: 'prompt-engineering-for-software-engineers',
          }),
          title: 'Learn How to Use AI for Coding',
          lessonCount: 2,
          grantsCertificate: true,
          enrollmentStatus: EnrollmentStatus.None,
          pro: false,
          shortDescription:
            'Ready to learn how to use AI for coding? Learn how to use generative AI tools like ChatGPT and GitHub Copilot to generate code and expedite your development.',
          difficulty: CourseDifficulty.Beginner,
          timeToComplete: getTimeToComplete(0.89),
          type: 'course',
        },
        {
          id: 'df046d8e34e3475e93642fa0efee2791',
          slug: 'intro-to-hugging-face',
          urlPath: getLearnPath({ slug: 'intro-to-hugging-face' }),
          title: 'Intro to Hugging Face',
          lessonCount: 1,
          grantsCertificate: true,
          enrollmentStatus: EnrollmentStatus.None,
          pro: false,
          shortDescription:
            'Learn about the Hugging Face AI and machine learning platform, and how their tools can streamline ML and AI development.',
          difficulty: CourseDifficulty.Beginner,
          timeToComplete: getTimeToComplete(0.34),
          type: 'course',
        },
      ],
      paths: [],
      categories: [],
    },
  },
  {
    title: 'Free',
    trackingTitle: 'free',
    description: ' ',
    relatedContent: {
      courses: [
        {
          id: 'ef9a06323b7d44b091a3ec7816092dc6',
          slug: 'intro-to-chatgpt',
          urlPath: getLearnPath({ slug: 'intro-to-chatgpt' }),
          title: 'Learn How to Use ChatGPT',
          lessonCount: 6,
          grantsCertificate: true,
          enrollmentStatus: EnrollmentStatus.None,
          pro: false,
          shortDescription:
            'Ready to dive into the world of Generative AI? Learn how ChatGPT works, how to use ChatGPT in your everyday life, and how to write effective ChatGPT prompts.',
          difficulty: CourseDifficulty.Beginner,

          timeToComplete: getTimeToComplete(1.04),
          type: 'course',
        },
        {
          id: '9eb0741e5ebef1f9f58a53bfac67d3a7',
          slug: 'learn-html',
          urlPath: getLearnPath({ slug: 'learn-html' }),
          title: 'Learn HTML',
          lessonCount: 6,
          grantsCertificate: true,
          enrollmentStatus: EnrollmentStatus.None,
          pro: false,
          shortDescription:
            'Start at the beginning by learning HTML basics — an important foundation for building and editing web pages.',
          difficulty: CourseDifficulty.Beginner,

          timeToComplete: getTimeToComplete(6.51),
          type: 'course',
        },
        {
          id: '705dcb15de0da4dd9d9fc4f3274b430e',
          slug: 'introduction-to-javascript',
          urlPath: getLearnPath({ slug: 'introduction-to-javascript' }),
          title: 'Learn JavaScript',
          lessonCount: 11,
          grantsCertificate: true,
          enrollmentStatus: EnrollmentStatus.None,
          pro: false,
          shortDescription:
            'Learn how to use JavaScript — a powerful and flexible programming language for adding website interactivity.',
          difficulty: CourseDifficulty.Beginner,

          timeToComplete: getTimeToComplete(14.75),
          type: 'course',
        },
        {
          id: '042a4e5884e3eb6ea1f2a12be6abb851',
          slug: 'learn-sql',
          urlPath: getLearnPath({ slug: 'learn-sql' }),
          title: 'Learn SQL',
          lessonCount: 4,
          grantsCertificate: true,
          enrollmentStatus: EnrollmentStatus.None,
          pro: false,
          shortDescription:
            "In this SQL course, you'll learn how to manage large datasets and analyze real data using the standard data management language.",
          difficulty: CourseDifficulty.Beginner,
          timeToComplete: getTimeToComplete(5),
          type: 'course',
        },
      ],
      paths: [],
      categories: [],
    },
  },
  {
    title: 'New to coding',
    trackingTitle: 'new_to_coding',
    description: ' ',
    relatedContent: {
      courses: [
        {
          id: '3d1976adb480406197a8e8765fe3f884',
          slug: 'choosing-a-programming-language-track',
          urlPath: getLearnPath({
            slug: 'choosing-a-programming-language-track',
          }),
          title: 'Choosing a Programming Language',
          lessonCount: 0,
          grantsCertificate: true,
          enrollmentStatus: EnrollmentStatus.None,
          pro: false,
          shortDescription:
            'This course will introduce learners to factors to consider when picking a first language as well as describe some popular options.',
          difficulty: CourseDifficulty.Beginner,
          timeToComplete: getTimeToComplete(0.22),
          type: 'course',
        },
        {
          id: 'f3b7fa80ff724a239a4397539f46fac7',
          slug: 'choosing-a-career-in-tech-track',
          urlPath: getLearnPath({ slug: 'choosing-a-career-in-tech-track' }),
          title: 'Choosing a Career in Tech',
          lessonCount: 0,
          grantsCertificate: true,
          enrollmentStatus: EnrollmentStatus.None,
          pro: false,
          shortDescription:
            'Are you feeling confused about where to aim for with your career goals? Take this course to learn about different career options!',
          difficulty: CourseDifficulty.Beginner,
          timeToComplete: getTimeToComplete(0.31),
          type: 'course',
        },
        {
          id: '38640ae98d1c419eabbe3534f3810039',
          slug: 'learn-to-code-with-blockly',
          urlPath: getLearnPath({ slug: 'learn-to-code-with-blockly' }),
          title: 'Learn to Code with Blockly',
          lessonCount: 5,
          grantsCertificate: true,
          enrollmentStatus: EnrollmentStatus.None,
          pro: false,
          shortDescription:
            'Want to learn how to get started with programming in an interactive way? Try our drag and drop code lessons!',
          difficulty: CourseDifficulty.Beginner,
          timeToComplete: getTimeToComplete(1.3),
          type: 'course',
        },
        {
          id: 'b8860151bce58aa30c6f272f89e22788',
          slug: 'welcome-to-codecademy',
          urlPath: getLearnPath({ slug: 'welcome-to-codecademy' }),
          title: 'Welcome To Codecademy',
          lessonCount: 1,
          grantsCertificate: false,
          enrollmentStatus: EnrollmentStatus.None,
          pro: false,
          shortDescription:
            'First time on our site? Start here to learn how to use our platform and write some basic code with JavaScript.',
          difficulty: CourseDifficulty.Beginner,
          timeToComplete: getTimeToComplete(0.22),
          type: 'course',
        },
      ],
      paths: [],
      categories: [],
    },
  },
  {
    title: 'Most popular',
    trackingTitle: 'most_popular',
    description: ' ',
    relatedContent: {
      courses: [
        {
          id: '6c152bd262967f8c941c9707ed636bda',
          slug: 'learn-python-3',
          urlPath: getLearnPath({ slug: 'learn-python-3' }),
          title: 'Learn Python 3',
          lessonCount: 14,
          grantsCertificate: true,
          enrollmentStatus: EnrollmentStatus.None,
          pro: true,
          shortDescription:
            'Learn the basics of Python 3, one of the most powerful, versatile, and in-demand programming languages today.\n',
          difficulty: CourseDifficulty.Beginner,
          timeToComplete: getTimeToComplete(25.13),
          type: 'course',
        },
        {
          id: '705dcb15de0da4dd9d9fc4f3274b430e',
          slug: 'introduction-to-javascript',
          urlPath: getLearnPath({ slug: 'introduction-to-javascript' }),
          title: 'Learn JavaScript',
          lessonCount: 11,
          grantsCertificate: true,
          enrollmentStatus: EnrollmentStatus.None,
          pro: false,
          shortDescription:
            'Learn how to use JavaScript — a powerful and flexible programming language for adding website interactivity.',
          difficulty: CourseDifficulty.Beginner,
          timeToComplete: getTimeToComplete(14.75),
          type: 'course',
        },
        {
          id: '9eb0741e5ebef1f9f58a53bfac67d3a7',
          slug: 'learn-html',
          urlPath: getLearnPath({ slug: 'learn-html' }),
          title: 'Learn HTML',
          lessonCount: 6,
          grantsCertificate: true,
          enrollmentStatus: EnrollmentStatus.None,
          pro: false,
          shortDescription:
            'Start at the beginning by learning HTML basics — an important foundation for building and editing web pages.',
          difficulty: CourseDifficulty.Beginner,
          timeToComplete: getTimeToComplete(6.51),
          type: 'course',
        },
        {
          id: 'b74a2390dfc4127fa5d43fe147425ad0',
          slug: 'learn-c-plus-plus',
          urlPath: getLearnPath({ slug: 'learn-c-plus-plus' }),
          title: 'Learn C++',
          lessonCount: 13,
          grantsCertificate: true,
          enrollmentStatus: EnrollmentStatus.None,
          pro: false,
          shortDescription:
            'Learn C++ — a versatile programming language that’s important for developing software, games, databases, and more.',
          difficulty: CourseDifficulty.Beginner,
          timeToComplete: getTimeToComplete(10.49),
          type: 'course',
        },
      ],
      paths: [],
      categories: [],
    },
  },
  {
    title: 'Skill paths',
    trackingTitle: 'skill_paths',
    description: ' ',
    relatedContent: {
      courses: [],
      paths: [
        {
          id: '5cadfefe5f1de806e9704577',
          slug: 'learn-how-to-build-websites',
          urlPath: getLearnPath({
            slug: 'learn-how-to-build-websites',
            isPath: true,
          }),
          imageUrl: getPathImageUrl('learn-how-to-build-websites'),
          goal: PathGoalEnum.Skill,
          title: 'Build a Website with HTML, CSS, and GitHub Pages',
          lessonCount: 18,
          courseCount: 9,
          enrollmentStatus: EnrollmentStatus.None,
          shortDescription:
            'Learn the basics of web development to build your own website.',
          difficulty: CourseDifficulty.Beginner,
          timeToComplete: getTimeToComplete(15.57),
          hasCareerJourney: careerPathHasJourney('learn-how-to-build-websites'),
          type: 'path',
        },
        {
          id: '5cafb2d937090210d7df3652',
          slug: 'analyze-data-with-sql',
          urlPath: getLearnPath({
            slug: 'analyze-data-with-sql',
            isPath: true,
          }),
          imageUrl: getPathImageUrl('analyze-data-with-sql'),
          goal: PathGoalEnum.Skill,
          title: 'Analyze Data with SQL',
          lessonCount: 15,
          courseCount: 8,
          enrollmentStatus: EnrollmentStatus.None,
          shortDescription:
            'Learn to analyze data with SQL and prepare for technical interviews.',
          difficulty: CourseDifficulty.Beginner,
          timeToComplete: getTimeToComplete(16.44),
          hasCareerJourney: careerPathHasJourney('analyze-data-with-sql'),
          type: 'path',
        },
        {
          id: '738a7262ff2b4a49b062c25482dc2549',
          slug: 'data-science-foundations',
          urlPath: getLearnPath({
            slug: 'data-science-foundations',
            isPath: true,
          }),
          imageUrl: getPathImageUrl('data-science-foundations'),
          goal: PathGoalEnum.Skill,
          title: 'Data Science Foundations',
          lessonCount: 49,
          courseCount: 15,
          enrollmentStatus: EnrollmentStatus.None,
          shortDescription:
            'Learn to clean, analyze, and visualize data with Python and SQL.',
          difficulty: CourseDifficulty.Beginner,
          timeToComplete: getTimeToComplete(55.7),
          hasCareerJourney: careerPathHasJourney('data-science-foundations'),
          type: 'path',
        },
        {
          id: '5c9ce0b45f1de879ebcad4fd',
          slug: 'build-web-apps-with-react',
          urlPath: getLearnPath({
            slug: 'build-web-apps-with-react',
            isPath: true,
          }),
          imageUrl: getPathImageUrl('build-web-apps-with-react'),
          goal: PathGoalEnum.Skill,
          title: 'Create a Front-End App with React',
          lessonCount: 24,
          courseCount: 9,
          enrollmentStatus: EnrollmentStatus.None,
          shortDescription:
            'Learn to build front-end web apps with JavaScript and React.',
          difficulty: CourseDifficulty.Intermediate,
          timeToComplete: getTimeToComplete(36.48),
          hasCareerJourney: careerPathHasJourney('build-web-apps-with-react'),
          type: 'path',
        },
      ],
      categories: [],
    },
  },
  {
    title: 'Career paths',
    trackingTitle: 'career_paths',
    description: ' ',
    relatedContent: {
      courses: [],
      paths: [
        {
          id: '5f85dd867b67b60014ac9ea3',
          slug: 'front-end-engineer-career-path',
          urlPath: getLearnPath({
            slug: 'front-end-engineer-career-path',
            isPath: true,
          }),
          imageUrl: getPathImageUrl('front-end-engineer-career-path'),
          goal: PathGoalEnum.Career,
          title: 'Front-End Engineer',
          lessonCount: 124,
          courseCount: 34,
          enrollmentStatus: EnrollmentStatus.None,
          shortDescription:
            'Front-end engineers work closely with designers to make websites beautiful, functional, and fast.',
          difficulty: CourseDifficulty.Beginner,
          timeToComplete: getTimeToComplete(116.67, true),
          hasCareerJourney: careerPathHasJourney(
            'front-end-engineer-career-path'
          ),
          type: 'path',
        },
        {
          id: '5f7e644d833c070013ef47c4',
          slug: 'full-stack-engineer-career-path',
          urlPath: getLearnPath({
            slug: 'full-stack-engineer-career-path',
            isPath: true,
          }),
          imageUrl: getPathImageUrl('full-stack-engineer-career-path'),
          goal: PathGoalEnum.Career,
          title: 'Full-Stack Engineer',
          lessonCount: 162,
          courseCount: 51,
          enrollmentStatus: EnrollmentStatus.None,
          shortDescription:
            'A full-stack engineer can get a project done from start to finish, back-end to front-end.',
          difficulty: CourseDifficulty.Beginner,
          timeToComplete: getTimeToComplete(149.3, true),
          hasCareerJourney: careerPathHasJourney(
            'full-stack-engineer-career-path'
          ),
          type: 'path',
        },
        {
          id: '5b5001a11d176d7acd8b2bf8',
          slug: 'computer-science',
          urlPath: getLearnPath({ slug: 'computer-science', isPath: true }),
          imageUrl: getPathImageUrl('computer-science'),
          goal: PathGoalEnum.Career,
          title: 'Computer Science',
          lessonCount: 82,
          courseCount: 6,
          enrollmentStatus: EnrollmentStatus.None,
          shortDescription:
            'Looking for an introduction to the theory behind programming? Master Python while learning data structures, algorithms, and more!',
          difficulty: CourseDifficulty.Beginner,
          timeToComplete: getTimeToComplete(79.07, true),
          hasCareerJourney: careerPathHasJourney('computer-science'),
          type: 'path',
        },
        {
          id: '5b520caa1d176d21f5a65a61',
          slug: 'data-science',
          urlPath: getLearnPath({ slug: 'data-science', isPath: true }),
          imageUrl: getPathImageUrl('data-science'),
          goal: PathGoalEnum.Career,
          title: 'Data Scientist: Machine Learning Specialist',
          lessonCount: 81,
          courseCount: 27,
          enrollmentStatus: EnrollmentStatus.None,
          shortDescription:
            'Machine Learning Data Scientists solve problems at scale, make predictions, find patterns, and more! They use Python, SQL, and algorithms.',
          difficulty: CourseDifficulty.Beginner,
          timeToComplete: getTimeToComplete(92.15, true),
          hasCareerJourney: careerPathHasJourney('data-science'),
          type: 'path',
        },
      ],
      categories: [],
    },
  },
];
