import { Box, FlexBox, IconButton, TextButton } from '@codecademy/gamut';
import { PauseIcon, PlayIcon } from '@codecademy/gamut-icons';
import styled from '@emotion/styled';
import React from 'react';

import { CarouselItem, transitionDuration } from './consts';

const Dot = styled(Box)`
  transition: background-color ${transitionDuration} ease-out;
`;

export const Controls: React.FC<{
  carouselItems: CarouselItem[];
  onChange: (i: number) => void;
  onPause: () => void;
  currentIndex: number;
  isPaused: boolean;
}> = ({ carouselItems, onChange, onPause, currentIndex, isPaused }) => (
  <Box
    as="ul"
    listStyle="none"
    aria-label="carousel controls"
    display="flex"
    alignItems="center"
    gap={4}
    height={36}
    py={0}
    px={8}
    m={0}
    bg="beige"
    borderRadius="18px"
  >
    <Box as="li">
      <Box
        as="ul"
        listStyle="none"
        display="flex"
        alignItems="center"
        p={0}
        m={0}
        pr={{ _: 0, md: 4 }}
        borderRight={{ _: 'none', md: 1 }}
      >
        {carouselItems.map((item, i) => (
          <FlexBox key={item.title.copy} as="li" alignItems="center">
            <TextButton
              aria-current={i === currentIndex}
              aria-disabled={i === currentIndex}
              aria-label={`go to slide ${i + 1}`}
              height={24}
              minWidth={24}
              onClick={() => onChange(i)}
              px={4}
              width="min-content"
            >
              <Dot
                width={12}
                height={12}
                border={1}
                borderRadius="50%"
                bg={i === currentIndex ? 'navy' : 'transparent'}
              />
            </TextButton>
          </FlexBox>
        ))}
      </Box>
    </Box>
    <Box as="li" display={{ _: 'none', md: 'list-item' }}>
      <IconButton
        size="small"
        icon={isPaused ? PlayIcon : PauseIcon}
        onClick={onPause}
        tip={isPaused ? 'unpause carousel' : 'pause carousel'}
      />
    </Box>
  </Box>
);
