import { breakpoints } from '@codecademy/gamut-styles';

export const trendingCourses = [
  {
    id: 'artificial-intelligence',
    text: 'AI',
    href: 'catalog/subject/artificial-intelligence',
  },
  {
    id: 'Python',
    text: 'Python',
    href: 'catalog/language/python',
  },
  {
    id: 'javascript',
    text: 'Javascript',
    href: 'catalog/language/javascript',
  },
  {
    id: 'data-science',
    text: 'Data science',
    href: 'catalog/subject/data-science',
  },
  {
    id: 'professional-skills',
    text: 'Professional skills',
    href: 'catalog/subject/professional-skills',
  },
  {
    id: 'cloud-computing',
    text: 'Cloud computing',
    href: 'catalog/subject/cloud-computing',
  },
  {
    id: 'html-css',
    text: 'HTML & CSS',
    href: 'catalog/language/html-css',
  },
  {
    id: 'c-plus-plus',
    text: 'C++',
    href: 'catalog/language/c-plus-plus',
  },
];

export const breakpointsWithBase = {
  _: '0px',
  ...breakpoints,
} as const;

// how many items to show per breakpoint before expanding
export const initialItemsPerBreakpoint = {
  _: 2,
  xs: 3,
  sm: 5,
};
