import { StrokeButton, TextButton } from '@codecademy/gamut';
import {
  MiniChevronDownIcon,
  MiniChevronUpIcon,
} from '@codecademy/gamut-icons';
import { Background } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';

export const SecondaryNavContainer = styled(Background)`
  display: grid;
  align-items: center;
`;

export const SecondaryNavStrokeButton = styled(StrokeButton)`
  border: 1px solid white;
  color: white;
  font-size: 14px;
  font-weight: 400;
  height: 32px;
  padding: 0 8px;
  stroke: white;
`;

export const DownChevron = styled(MiniChevronDownIcon)`
  color: white;
  margin-left: 8px;
`;

export const UpChevron = styled(MiniChevronUpIcon)`
  color: white;
  margin-left: 8px;
`;

export const ExpandButton = styled(TextButton)`
  color: white;
  font-size: 14px;
  height: 32px;
  padding-left: 0px;
  padding-right: 0px;
`;
