import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloClientService } from '@mono/apollo';
import {
  GetHomepageContentfulQuery,
  GetHomepageContentfulQueryVariables,
} from '@mono/data/codegen/contentful';
import { fetchCacher } from '@mono/data/fetch-cachers';
import { logError } from '@mono/data/logging';
import {
  Experiment,
  isOptimizelyExperimentActive,
} from '@mono/data/optimizely';
import { redirect } from '@mono/util/next';
import { ReactSDKClient } from '@optimizely/react-sdk';
import { GetServerSidePropsContext } from 'next';

import { defaultCtaBlockData } from './components/CTABlock/consts';
import {
  defaultCarouselItems,
  indiaLearnerStorySlide,
} from './components/HeroSignUpSection/Carousel/consts';
import { defaultPlatformShowcaseData } from './components/PlatformShowcase/consts';
import {
  defaultGoalsData,
  defaultHeroTitle,
  defaultLearnerStoriesData,
} from './consts';
import {
  fetchAndNormalizeGoalsData,
  getHomepageHeroTitle,
  normalizeCarouselData,
  normalizeCtaBlockData,
  normalizeLearnerStoriesData,
  normalizePlatformShowcase,
} from './helpers/contentfulData';
import { getHomepageContentfulQuery } from './queries.contentful';
import { queryPricingDetails } from './queries.graphql-gateway';
import {
  NullableCarouselData,
  NullableCtaBlock,
  NullableExperimentalHeroTitle,
  NullableLearnerStoriesData,
  NullablePlatformShowcaseData,
  RawGoal,
} from './types';

const fetchHomepageContentfulDataCached = (
  client: ApolloClient<NormalizedCacheObject>,
  isUserInIndia: boolean
) => {
  const locale = isUserInIndia ? 'en-IN' : 'en-US';
  const isProd = process.env.NODE_ENV === 'production';

  const f = async () => {
    const { data } = await client.query<
      GetHomepageContentfulQuery,
      GetHomepageContentfulQueryVariables
    >({
      query: getHomepageContentfulQuery,
      variables: { locale, preview: !isProd },
      context: { service: ApolloClientService.Contentful },
    });
    return data.homepage;
  };

  return isProd
    ? fetchCacher(`homepage-contentful-${locale}`, f, {
        // 15 minutes, intended to be a happy medium
        // between notable caching gains and not making
        // marketing folks wait long to see updates
        revalidateMs: 15 * 60 * 1000,
      })
    : f();
};

export const getHomepageProps = async (
  client: ApolloClient<NormalizedCacheObject>,
  context: GetServerSidePropsContext & {
    optimizely?: ReactSDKClient;
    user?: { anonymous?: boolean };
  }
) => {
  if (context.user && !context.user.anonymous) {
    return redirect('/learn');
  }

  const isUserInIndia = !!context.req.headers['cf-ipcountry']?.includes('IN');

  const defaultCarouselLocalized = isUserInIndia
    ? [indiaLearnerStorySlide, ...defaultCarouselItems]
    : defaultCarouselItems;

  let isPricingExperimentEnabled = false;

  if (context.req.headers['cf-ipcountry']?.includes('US')) {
    isPricingExperimentEnabled = isOptimizelyExperimentActive(
      context.optimizely,
      Experiment.PRODUCT_FEATURE_MARKETING_PRICING
    );
  }

  const showSecondaryNav = isOptimizelyExperimentActive(
    context.optimizely,
    Experiment.SHOW_SECONDARY_NAV
  );

  try {
    const homepage = await fetchHomepageContentfulDataCached(
      client,
      isUserInIndia
    );

    const response = await client.query({
      query: queryPricingDetails,
      context: {
        service: ApolloClientService.GraphqlGateway,
      },
    });
    const pricingData = { ...response.data.pricing };

    const learnerStories = !homepage?.learnerStories
      ? defaultLearnerStoriesData
      : normalizeLearnerStoriesData(
          homepage.learnerStories as NullableLearnerStoriesData,
          context.optimizely
        );

    const goals = !homepage?.goalsCollection
      ? defaultGoalsData
      : await fetchAndNormalizeGoalsData(
          client,
          homepage.goalsCollection.items as RawGoal[]
        );

    const platformShowcase = !homepage?.platformShowcase
      ? defaultPlatformShowcaseData
      : normalizePlatformShowcase(
          homepage.platformShowcase as NullablePlatformShowcaseData,
          context.optimizely
        );

    const heroTitle = getHomepageHeroTitle(
      homepage?.heroTitle,
      homepage?.experimentalHeroTitle as NullableExperimentalHeroTitle,
      context.optimizely
    );

    const carousel = normalizeCarouselData(
      homepage?.carousel as NullableCarouselData,
      defaultCarouselLocalized,
      context.optimizely
    );

    const ctaBlock = !homepage?.ctaBlock
      ? defaultCtaBlockData
      : normalizeCtaBlockData(
          homepage.ctaBlock as NullableCtaBlock,
          context.optimizely
        );

    return {
      props: {
        contentfulData: {
          heroTitle,
          carousel,
          learnerStories,
          goals,
          platformShowcase,
          ctaBlock,
        },
        isUserInIndia,
        isPricingExperimentEnabled,
        showSecondaryNav,
        ...pricingData,
      },
    };
  } catch (err) {
    logError(err, { message: `Homepage fetch error: ${err}` });
  }

  return {
    props: {
      contentfulData: {
        heroTitle: defaultHeroTitle,
        carousel: defaultCarouselLocalized,
        learnerStories: defaultLearnerStoriesData,
        goals: defaultGoalsData,
        platformShowcase: defaultPlatformShowcaseData,
        ctaBlock: defaultCtaBlockData,
      },
      isUserInIndia,
      showSecondaryNav,
    },
  };
};
