import { Box, FlexBox, Text } from '@codecademy/gamut';
import { UserClickData } from '@codecademy/tracking';
import { MediaQueryMap } from '@codecademy/variance';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, {
  ComponentProps,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { breakpointsWithBase, initialItemsPerBreakpoint } from './consts';
import {
  DownChevron,
  ExpandButton,
  SecondaryNavStrokeButton,
  UpChevron,
} from './styles';

type NavItemProps = {
  itemsToShow: MediaQueryMap<number>;
};

type ShowMoreTextProps = {
  index: number;
};

type NavCollapseProps = ComponentProps<typeof FlexBox> & {
  children: React.ReactNode;
  trendingNavItems: { id: string; href: string; text: string }[];
  trackUserClick?: (data: UserClickData) => void;
};

const getMediaQuery = ({
  css,
  start,
  end,
}: {
  css: string;
  start: string;
  end?: string;
}) =>
  end
    ? `@media only screen and (min-width: ${start}) and (max-width: calc(${end} - 1px)) { ${css} }`
    : `@media only screen and (min-width: ${start}) { ${css} }`;

const NavItem = styled(Box)<NavItemProps>`
  ${({ itemsToShow }) =>
    (Object.keys(itemsToShow) as (keyof MediaQueryMap<number>)[]).map(
      (breakpointKey, i, breakpointKeys) => {
        const max = itemsToShow[breakpointKey];
        if (max === undefined) return;

        const start = breakpointsWithBase[breakpointKey];
        const end = breakpointsWithBase[breakpointKeys[i + 1]];

        // hide all list items after the max, which includes the title
        const css = `&:nth-of-type(n + ${max + 2}) { display: none; }`;

        return i === breakpointKeys.length - 1
          ? getMediaQuery({ css, start })
          : getMediaQuery({ css, start, end });
      }
    )}
`;

const ShowMoreText = styled(Text)<ShowMoreTextProps>`
  ${({ index }) => {
    const breakpointKeys = Object.keys(
      breakpointsWithBase
    ) as (keyof typeof breakpointsWithBase)[];
    const rules = breakpointKeys.map((breakpointKey, i) => {
      const start = breakpointsWithBase[breakpointKey];
      const end = breakpointsWithBase[breakpointKeys[i + 1]];

      let cssRule = '';

      // decide which text to show based on breakpoint
      if (index === 0 && breakpointKey === '_') {
        cssRule = 'display: inline-block;';
      } else if (index === 1 && breakpointKey === 'xs') {
        cssRule = 'display: inline-block;';
      } else if (index === 2 && breakpointKey === 'sm') {
        cssRule = 'display: inline-block;';
      } else {
        cssRule = 'display: none;';
      }

      return i === breakpointKeys.length - 1
        ? getMediaQuery({ css: cssRule, start })
        : getMediaQuery({ css: cssRule, start, end });
    });

    return css`
      ${rules.join(' ')}
    `;
  }}
`;

// calculate number of items to be shown per breakpoint, based on whether the nav is expanded
const calculateVisibleItems = (isExpanded: boolean, totalItemCount: number) => {
  return (
    Object.keys(
      initialItemsPerBreakpoint
    ) as (keyof typeof initialItemsPerBreakpoint)[]
  )
    .map((breakpointKey) => ({
      [breakpointKey]: isExpanded
        ? totalItemCount
        : initialItemsPerBreakpoint[breakpointKey],
    }))
    .reduce((acc, cur) => ({ ...acc, ...cur }), {});
};

export const NavCollapse: React.FC<NavCollapseProps> = ({
  children,
  trendingNavItems,
  trackUserClick,
  ...flexBoxProps
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const expandedNavRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isExpanded && expandedNavRef.current) {
      expandedNavRef.current.focus();
    }
  }, [isExpanded]);

  const totalItemCount = trendingNavItems.length;

  const visibleItemCount = useMemo(
    () => calculateVisibleItems(isExpanded, totalItemCount),
    [isExpanded, totalItemCount]
  );

  return (
    <FlexBox
      as="ul"
      listStyleType="none"
      wrap
      tabIndex={-1}
      ref={expandedNavRef}
      m={0}
      p={0}
      {...flexBoxProps}
    >
      {children}
      {trendingNavItems.map((item) => (
        <NavItem
          key={item.id}
          itemsToShow={visibleItemCount}
          as="li"
          m={0}
          p={0}
        >
          <SecondaryNavStrokeButton
            href={item.href}
            onClick={() =>
              trackUserClick?.({
                context: 'secondary_nav',
                target: item.id,
              })
            }
          >
            {item.text}
          </SecondaryNavStrokeButton>
        </NavItem>
      ))}
      <Box as="li" m={0} p={0}>
        <ExpandButton
          variant="secondary"
          onClick={() => setIsExpanded(!isExpanded)}
          aria-expanded={isExpanded}
        >
          {isExpanded ? (
            <>
              Show less <UpChevron size={12} />
            </>
          ) : (
            <>
              {Object.entries(visibleItemCount).map(
                ([breakpointKey, itemCount], i) => (
                  <ShowMoreText key={breakpointKey} index={i}>
                    Show {totalItemCount - itemCount} more
                  </ShowMoreText>
                )
              )}
              <DownChevron size={12} />
            </>
          )}
        </ExpandButton>
      </Box>
    </FlexBox>
  );
};
