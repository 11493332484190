import { Box, FlexBox, Text } from '@codecademy/gamut';
import { GraphStatsAscendIcon } from '@codecademy/gamut-icons';
import { UserClickData } from '@codecademy/tracking';
import * as React from 'react';

import { trendingCourses } from './consts';
import { NavCollapse } from './NavCollapse';
import { SecondaryNavContainer, SecondaryNavStrokeButton } from './styles';

type SecondaryNavProps = {
  trackUserClick?: (data: UserClickData) => void;
};

const SecondaryNavTitle: React.FC = () => {
  return (
    <FlexBox id="secondarynav-title" as="li" alignItems="center" gap={8} mr={4}>
      <GraphStatsAscendIcon />
      <Text variant="p-small" fontWeight={700} lineHeight={2 as 0}>
        Trending
      </Text>
    </FlexBox>
  );
};

export const SecondaryNavigation: React.FC<SecondaryNavProps> = ({
  trackUserClick,
}) => {
  return (
    <SecondaryNavContainer bg="navy">
      <Box as="nav" my={16} aria-labelledby="secondarynav-title">
        <FlexBox
          mx="auto"
          my={0}
          px={{ _: 16, xs: 32, sm: 64, lg: 96 }}
          maxWidth={1440}
          alignItems="center"
          width="100%"
        >
          <Box
            display={{ _: 'none', md: 'flex' }}
            as="ul"
            listStyleType="none"
            m={0}
            p={0}
            gap={12}
          >
            <SecondaryNavTitle />
            {trendingCourses.map((course) => {
              return (
                <Box as="li" m={0} p={0} key={course.id}>
                  <SecondaryNavStrokeButton
                    href={course.href}
                    onClick={() =>
                      trackUserClick?.({
                        context: 'secondary_nav',
                        target: course.id,
                      })
                    }
                  >
                    {course.text}
                  </SecondaryNavStrokeButton>
                </Box>
              );
            })}
          </Box>
          <Box display={{ _: 'flex', md: 'none' }} p={0}>
            <NavCollapse
              trendingNavItems={trendingCourses}
              trackUserClick={trackUserClick}
              gap={12}
            >
              <SecondaryNavTitle />
            </NavCollapse>
          </Box>
        </FlexBox>
      </Box>
    </SecondaryNavContainer>
  );
};
